<template>
  <BaseModal
    size="lg"
    :show="show"
    title="Delete Folder"
    @close="$emit('close')"
  >
    <div class="mb-6">
      Are you sure you want to delete the folder
      <span class="font-semibold">{{ folder.name }}</span>
      <span>?</span>
      It will also delete all prompts and subfolders within this folder.
    </div>
    <div class="flex items-center">
      <BaseButton :loading="submitting" @click="handleConfirm">Yes</BaseButton>
      <BaseButton class="ml-2" variant="white" @click="$emit('close')">
        No
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import usePromptStore from '~/stores/usePromptStore'
import useApiFetch from '~/composables/useApiFetch'
import useFolderStore from '~/stores/useFolderStore'

const emit = defineEmits(['close', 'delete'])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  folder: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      category: 'chatgpt',
    }),
  },
})

const folderStore = useFolderStore()
const promptStore = usePromptStore()
const { folders } = storeToRefs(promptStore)
const submitting = ref(false)

const handleConfirm = () => {
  submitting.value = true
  folderStore.remove({ folderId: props.folder.id })

  emit('delete')

  useApiFetch(`/folders/${props.folder.id}`, {
    method: 'DELETE',
  })

  submitting.value = false

  emit('close')
}
</script>
