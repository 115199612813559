<template>
  <div class="flex items-center">
    <div
      class="flex-1 px-2.5 text-sm uppercase font-semibold leading-6 text-gray-400"
    >
      Folders
    </div>
    <a
      href="#"
      class="hover:bg-white px-2 py-1 rounded"
      @click.prevent="handleShowFolderCreate"
    >
      <BaseIcon name="heroicons:plus-20-solid" size="1.1em" color="gray" />
    </a>
  </div>
  <div v-show="showFolderCreate" class="mt-2 px-2.5">
    <form @submit.prevent="handleFolderCreate">
      <input
        ref="folderName"
        v-model="folderForm.name"
        class="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        type="text"
        @keydown="handleFolderKeydown"
        placeholder="Folder name then hit enter..."
      />
    </form>
  </div>
  <PromptFolders
    v-if="!folders.pending && folders?.data?.length > 0"
    :folders="folders?.data"
    :selected="selected"
    @sort="handleSort"
  />
  <div
    v-if="!folders.pending && folders?.data?.length === 0 && !showFolderCreate"
    class="px-2.5"
  >
    <SidebarEmptyFolders @new="handleShowFolderCreate" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { Folder } from '~/types'
import useFolderStore from '~/stores/useFolderStore'
import useApiFetch from '~/composables/useApiFetch'
import useEventBus, { EVENTS } from '~/composables/useEventBus'

const props = defineProps({
  initialValues: {
    type: Object,
    default: () => {},
  },
  selected: {
    type: Number,
    default: null,
  },
})

const emit = defineEmits(['create', 'sort', 'select', 'new'])
const folderStore = useFolderStore()
const { folders } = storeToRefs(folderStore)
const folderForm = ref({ name: '', ...props.initialValues })
const createFolderEventBus = useEventBus({ name: EVENTS.CREATE_FOLDER })
const folderName = ref(null)
const showFolderCreate = ref(false)

const handleShowFolderCreate = () => {
  if (showFolderCreate.value) {
    showFolderCreate.value = false
    return
  }

  folderForm.value.name = ''
  showFolderCreate.value = true

  nextTick(() => {
    // @ts-ignore
    folderName.value.focus()
  })
}

const handleFolderCreate = async () => {
  const { data: folder } = await useApiFetch('/folders', {
    method: 'POST',
    body: folderForm.value,
  })

  folderStore.add({ folder: folder.value })
  folderForm.value.name = ''

  handleShowFolderCreate()
  createFolderEventBus.emit({ folder: folder.value })
}

const handleSort = (event: any) => {
  const { newIndex, oldIndex } = event.moved

  folders.value.data = swapItems(folders.value.data, oldIndex, newIndex)

  useApiFetch('/folders/sort', {
    method: 'POST',
    body: {
      id: folders.value.data.map((folder: Folder) => folder.id),
    },
  })
}

const handleFolderKeydown = (event: any) => {
  if (event.key === 'Escape') {
    folderForm.value.name = ''
    showFolderCreate.value = false
  }
}
</script>
