<template>
  <div v-if="promptTags.data && promptTags.data.length > 0">
    <div
      class="px-2.5 flex-1 text-sm uppercase font-semibold leading-6 text-gray-400"
    >
      Tags
    </div>
    <div class="mt-2 space-y-0.5">
      <a
        v-for="tag in promptTags.data"
        href="#"
        @click.prevent="() => handleSelect(tag)"
        :class="[
          route.query.term?.includes(tag.value)
            ? 'bg-gray-100 text-indigo-700'
            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
          'group flex relative gap-x-3 py-1 px-2.5 rounded text-sm leading-6 font-semibold items-center cursor-pointer',
        ]"
      >
        <div class="flex w-full">
          <div class="flex-1 flex items-center">
            <BaseIcon name="heroicons:hashtag" size="1em" color="gray" />
            <div class="ml-1.5 truncate">{{ tag.value }}</div>
          </div>
          <div>{{ tag.count }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import usePromptStoreRefs from '~/composables/usePromptStoreRefs'
import useEventBus, { EVENTS } from '~/composables/useEventBus'

const { promptTags } = usePromptStoreRefs()
const searchBus = useEventBus({ name: EVENTS.SEARCH })
const route = useRoute()
const handleSelect = (tag: any) => {
  searchBus.emit({ term: `"#${tag.value}"` })
}
</script>
