<template>
  <div class="flex flex-col bg-gray-200 w-11 flex-none">
    <BaseLink href="/prompts/home">
      <div class="flex justify-center items-center h-16">
        <img src="~/assets/logo-128.png" class="w-7 h-7 rounded" />
      </div>
    </BaseLink>
    <div class="flex flex-col items-center justify-center">
      <BaseLink
        href="/prompts/folders/all"
        :class="[isActive('/prompts') ? 'bg-gray-50' : '']"
        class="flex justify-center items-center w-full h-[44px]"
      >
        <BaseIcon
          name="heroicons:pencil-square"
          class="w-5 h-5"
          :class="[isActive('/prompts') ? 'text-indigo-700' : '']"
        />
      </BaseLink>
      <BaseLink
        href="/chats/all"
        :class="[isActive('/chats') ? 'bg-gray-50' : '']"
        class="flex justify-center items-center w-full h-[44px]"
      >
        <BaseIcon
          name="heroicons:chat-bubble-left"
          class="w-5 h-5"
          :class="[isActive('/prompts') ? 'text-indigo-700' : '']"
        />
      </BaseLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import useAuthStore from '~/stores/useAuthStore'

const authStore = useAuthStore()
const canBeta = authStore.can('beta')
const route = useRoute()

const isActive = (path: string) => {
  return route.fullPath.includes(path)
}
</script>
