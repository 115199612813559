<template>
  <BaseModal size="lg" :show="show" :title="title" @close="$emit('close')">
    <FormKit v-model="form" type="form" :actions="false" @submit="handleSubmit">
      <div class="grid gap-y-5">
        <FormKit type="text" name="name" label="Name" />
        <BaseButton :loading="submitting">Save</BaseButton>
      </div>
    </FormKit>
  </BaseModal>
</template>

<script setup lang="ts">
import useFolderStore from '~/stores/useFolderStore'
import useApiFetch from '~/composables/useApiFetch'
import useFullScreenModal from '~/composables/useFullScreenModal'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  initialValues: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      category: 'chatgpt',
    }),
  },
})
const emit = defineEmits(['close', 'confirm', 'update'])
const form = ref<any>({})
const title = computed(() =>
  props.initialValues.id ? 'Edit Folder' : 'New Folder'
)
const folderStore = useFolderStore()
const submitting = ref(false)
const fullScreenModal = useFullScreenModal()

watch(
  () => props.initialValues,
  () => {
    form.value = { ...props.initialValues }
  }
)

const handleSubmit = async () => {
  submitting.value = true

  folderStore.update({ folder: form.value })

  await useApiFetch(`/folders/${form.value.id}`, {
    method: 'PUT',
    body: { name: form.value.name },
  })

  emit('update', form.value)
  submitting.value = false
}
</script>
