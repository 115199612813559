<template>
  <VueDraggableNext class="mt-2 space-y-1" :list="folders" @change="handleSort">
    <FolderItem
      v-for="folder in folders"
      :key="folder.id"
      :folder="folder"
      :selected="selected"
    />
  </VueDraggableNext>
</template>

<script setup lang="ts">
import { VueDraggableNext } from 'vue-draggable-next'

defineProps({
  selected: {
    type: Number,
    default: false,
  },
  folders: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['sort'])

const handleSort = (event: any) => {
  emit('sort', event)
}
</script>
