<template>
  <BaseLink
    :href="`${baseUrl}/${folder.id}`"
    :class="[
      selected == folder.id
        ? 'bg-gray-100 text-indigo-700'
        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
      'group flex relative gap-x-3 py-1.5 px-2.5 rounded text-sm leading-6 font-semibold items-center cursor-pointer',
    ]"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <BaseIcon
      name="heroicons:folder"
      class="w-5 h-5 shrink-0"
      size="1.3em"
      :class="
        selected == folder.id
          ? 'text-indigo-600'
          : 'text-gray-400 group-hover:text-indigo-600'
      "
    />
    <div>{{ folder.name }}</div>
    <FolderDropdown
      v-if="hover || menuOpen"
      :hover="hover"
      @click="menuOpen = true"
      @delete="handleDelete"
      @edit="handleEdit"
      @share="handleShare"
    />
  </BaseLink>
</template>

<script setup lang="ts">
import type { Folder } from '~/types'
import useEventBus, { EVENTS } from '~/composables/useEventBus'

interface IFolderEmit {
  folder: Folder
}

const props = defineProps({
  folder: {
    type: Object as PropType<Folder>,
    required: true,
  },
  selected: {
    type: Number,
    default: null,
  },
})

const route = useRoute()
const { folder } = toRefs(props)
const deleteBus = useEventBus<IFolderEmit>({ name: EVENTS.DELETE_FOLDER })
const editBus = useEventBus<IFolderEmit>({ name: EVENTS.EDIT_FOLDER })
const shareBus = useEventBus<IFolderEmit>({ name: EVENTS.SHARE_FOLDER })
const baseUrl = computed(() => {
  return route.fullPath.split('/')[1] === 'chats'
    ? `/chats`
    : `/prompts/folders`
})
const hover = ref(false)
const menuOpen = ref(false)

const handleDelete = () => {
  deleteBus.emit({ folder: folder.value })
}

const handleEdit = () => {
  editBus.emit({ folder: folder.value })
}

const handleShare = () => {
  shareBus.emit({ folder: folder.value })
}
</script>
